import { Component, computed, inject, input, signal } from '@angular/core';
import { ArkLinkCardComponent } from '../link-card/link-card.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { APP_ROUTES } from '@shared/helpers';
import { MatIconModule } from '@angular/material/icon';
import { DefaultEventIcon } from '@index/enums';
import { GthPlaceModel } from '@sentinels/models/place';
import { Store } from '@ngrx/store';
import { APP_STATE } from '../../../../../gth-legacy/src/public-api';

const DEFAULT_IMG = 'assets/Gametime-Hero_Twitter-Card_800x418.png';

@Component({
  selector: 'ark-place-card',
  standalone: true,
  imports: [ArkLinkCardComponent, MatButtonModule, RouterModule, MatIconModule],
  templateUrl: './place-card.component.html',
  styleUrl: './place-card.component.scss',
})
export class ArkPlaceCardComponent {
  place = input.required<GthPlaceModel>();
  width = input<string | undefined>(undefined);

  router = inject(ActivatedRoute);
  store = inject(Store<APP_STATE>);

  sportIcon = DefaultEventIcon;
  errorAsset = DEFAULT_IMG;
  routes = APP_ROUTES;
  cardLink = computed(() => [this.routes.Places, 'view', this.place().id]);

  queryParams = signal({ context: 'places' });
  bannerError = false;

  bannerImg = computed(() => this.place().getImage() || DEFAULT_IMG);

  inReview = computed(() => !this.place().reviewAccepted);

  onClick() {
    this.bannerImg = computed(() => {
      return this.place().getImage() || DEFAULT_IMG;
    });
  }
}
